<template>
  <div id="mentions_legales_cristal_commandes">
    <CguCristalCommandesDe v-if="country === 'de'" />
    <CguCristalCommandesFr v-else />
  </div>
</template>

<script>
import CguCristalCommandesFr from "@/components/legal/cc/CguCristalCommandesFr.vue";
import CguCristalCommandesDe from "@/components/legal/cc/CguCristalCommandesDe.vue";

import { mapGetters } from "vuex";

/**
 * Affiche les CGU de Cristal Commandes dans la bonne langue.
 */
export default {
  name: "CguCristalCommandes",
  components: {
    CguCristalCommandesFr,
    CguCristalCommandesDe,
  },
  computed: {
    ...mapGetters(["country"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>
