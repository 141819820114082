<template>
  <div
    id="cgu_cristal_commandes_fr"
    class="text-regular"
  >
    <p class="bloc bold">
      Date&nbsp;: mai 2021
    </p>

    <div class="bloc">
      <h2 class="s4 light">
        Définitions
      </h2>
      <p>
        Les Parties conviennent et acceptent que les termes suivants utilisés avec une majuscule, au singulier
        et/ou au pluriel, auront, dans le cadre des présentes conditions générales d’utilisation ("CGU"), la
        signification définie ci-après&nbsp;:
        <ul>
          <li>
            "Site" désigne la plateforme numérique du type site web permettant l’accès au Service ainsi que son
            utilisation;
          </li>
          <li>
            "Utilisateur" désigne toute personne/internaute qui se connecte au Site.
          </li>
        </ul>
      </p>
      <p>
        Les présentes conditions générales d’utilisation ont pour objet de définir les conditions dans
        lesquelles, LDE (au capital de 500&nbsp;000,00&nbsp;€, immatriculée au RCS de Strasbourg sous
        le numéro 395 135 999) consent à un Utilisateur un droit d’utilisation, incessible et non exclusif sur les
        informations publiées, le (ou les) document(s) numérique(s), service(s) accessible(s) sur le Site internet
        de LDE pour le monde entier.
      </p>
      <p>
        L’acceptation des présentes conditions générales d’utilisation se matérialise notamment par l’usage du
        Site.<br />
        De plus, toute inscription ou utilisation du Site implique l’acceptation sans aucune réserve ni
        restriction des présentes CGU par l’Utilisateur. Lors de l’inscription au Site via le formulaire
        d’inscription mis à disposition par les équipes de LDE, chaque Utilisateur accepte expressément les
        présentes CGU.
      </p>
      <p>
        LDE se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU. Dans ce
        cas, une nouvelle demande d’acceptation sera présentée à l’Utilisateur.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 1&nbsp;: Accès au Site
      </h2>
      <p>
        Toute personne peut accéder au Site, dès lors qu’elle dispose d’une connexion internet. L’accès à
        proprement parlé aux services du Site n’est possible et accessible qu’après la conclusion d’un contrat
        avec LDE. Concrètement, il faut pouvoir s’authentifier pour naviguer et profiter des différentes
        fonctionnalités du Site. L’Utilisateur non authentifié n’a pas accès aux différents services. Afin
        d’obtenir un identifiant, il suffit de contacter le service commercial de LDE.
        <!-- TODO: Ajouter un lien vers le formulaire de demande de compte ? -->
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 2&nbsp;: Disponibilité du site internet
      </h2>
      <p>
        LDE s’efforce d’assurer la disponibilité du Site 24 h/24 et 7 j/7. Cependant, l’accès au Site peut être
        interrompu notamment dans le cadre d’opérations de maintenance, de mises à niveau ou de mises à jour, de
        réparations d’urgence, ou par suite de circonstances indépendantes de la volonté de LDE.
      </p>
      <p>
        Toutefois, LDE s’engage à faire de la disponibilité de son Site une priorité et à informer l’Utilisateur
        de toute opération de maintenance programmée.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 3&nbsp;: Obligations de l’Utilisateur
      </h2>
      <p>
        LDE s’assure de la comptabilité de son environnement technique avec le Site. En outre, l’Utilisateur est
        tenu d’effectuer des sauvegardes régulières des données utiles à ses activités et des informations
        enregistrées lors de l’utilisation du Site.
      </p>
      <p>
        Les noms d’Utilisateur et les mots de passe utilisés et/ou communiqués à l’Utilisateur sont personnels,
        confidentiels et non-transmissibles. L’usage de ces éléments d’authentification est placé sous la seule
        responsabilité de l’Utilisateur. Ledit Utilisateur s’interdit de transmettre ou de communiquer ces
        éléments à des tiers non autorisés, s’engage à avertir sans délai LDE par écrit en cas de perte ou de vol
        de ces éléments.
      </p>
      <p>
        L’Utilisateur s’engage à informer, sans délai et par écrit, LDE de toutes modifications des informations
        le concernant (telles que les coordonnées de facturation, références bancaires, etc.) et/ou ayant une
        incidence ou susceptibles d’avoir une incidence sur le périmètre de la licence d’utilisation concédée.
      </p>
      <p>
        LDE se réserve le droit, en cas de non-respect des présentes, de couper l’accès de l’Utilisateur au Site.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 4&nbsp;: Propriété intellectuelle
      </h2>
      <p>
        L’ensemble du Site est régi par la législation française sur le droit d’auteur et la propriété
        intellectuelle. Ainsi, tout le contenu présent sur le Site, incluant, de façon non limitative, les
        graphismes, les images, les textes, les vidéos, les animations, les sons, les logos, les gifs et les
        icônes, ainsi que leur mise en forme sont la propriété exclusive de LDE à l'exception des marques, logos
        ou contenus appartenant à d'autres sociétés partenaires ou auteurs.
      </p>
      <p>
        Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle,
        de ces différents éléments est strictement interdite sans autorisation expresse et écrite de LDE.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 5&nbsp;: Traitement des données à caractère personnel
      </h2>
      <p>
        LDE accorde une grande importance à la protection des données à caractère personnel de ses partenaires et
        de ses utilisateurs. C’est pourquoi une
        <router-link :to="{ name: 'rgpd', }">
          charte de protection des données
        </router-link>
        a été mise en place. La charte pourra être modifiée à tout moment par LDE, au gré des modifications
        effectuées, afin notamment de se conformer à toute évolution réglementaire, jurisprudentielle, éditoriale
        et technique. Il est conseillé de consulter la charte avant toute navigation et utilisation du Site.
      </p>
      <p>
        LDE respecte le règlement n°2016-679 du 27 avril 2016 relatif à la protection des personnes physiques à
        l'égard du traitement des données à caractère personnel et à la libre circulation de ces données ("RGPD"),
        en complément de la loi n°78-17 du 6 janvier 1978 modifiée relative à l'informatique, aux fichiers et aux
        libertés (dite loi "Informatique et Libertés") ainsi que la loi n° 2004-575 du 21 juin 2004 pour la
        confiance dans l’économie numérique. Avec cette charte, LDE souhaite renforcer sa politique de protection
        des données personnelles afin que les données de l’Utilisateur soient collectées et utilisées de manière
        transparente, confidentielle et sécurisée.
      </p>
      <p>
        Les données à caractère personnel font l’objet d’un traitement informatique par LDE agissant en qualité de
        responsable de traitement dont la finalité du traitement est prévue dans la charte.
      </p>
      <p>
        Les données à caractère personnel sont conservées pendant la durée strictement nécessaire à
        l’accomplissement des finalités rappelées au sein de la charte.
      </p>
      <p>
        Conformément à la réglementation en vigueur, les Utilisateurs de notre plateforme disposent des droits
        suivants&nbsp;:
      </p>
      <ul>
        <li>droit d'accès;</li>
        <li>droit de rectification si leurs données sont erronées ou obsolètes (mise à jour et complétude);</li>
        <li>droit à l’oubli pour effacer leurs données;</li>
        <li>droit de retirer à tout moment un consentement;</li>
        <li>droit à la limitation du traitement;</li>
        <li>droit d'opposition aux traitements des données personnelles;</li>
        <li>
          droit à la portabilité des données fournies, lorsque ces données font l'objet de traitements automatisés
          fondés sur leur consentement ou un contrat;
        </li>
        <li>
          droit de définir le sort des données à caractère personnel à la mort d’un Utilisateur et de choisir à
          qui LDE devra communiquer ses données (personne préalablement désignée).
        </li>
      </ul>
      <p>
        En cas d’exercice du droit d’opposition, toute communication auprès de l’Utilisateur (à l’exclusion de la
        gestion de son compte) cessera.
      </p>
      <p>
        Pour une information complète sur le traitement des données à caractère personnel, il convient de se
        reporter à notre
        <router-link :to="{ name: 'rgpd', }">
          charte de protection des données
        </router-link>.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 6&nbsp;: Cookies
      </h2>
      <p>
        Afin d’améliorer et de personnaliser la navigation, des cookies peuvent être déposés sur l’ordinateur de
        l’Utilisateur. Il est possible de refuser ces cookies à l’aide du bandeau prévu à cet effet ou en
        paramétrant le navigateur utilisé. Pour en savoir d'avantage sur le fonctionnement des cookies,
        l’Utilisateur peut se référer à la
        <router-link :to="{ name: 'rgpd', }">
          charte de protection des données
        </router-link>
        mise en place.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 7&nbsp;: Responsabilité
      </h2>
      <p>
        LDE prend le soin de s’assurer de la qualité et de la fiabilité des informations contenues sur le Site.
      </p>
      <p>
        LDE ne peut pas garantir l’exactitude, la complétude et l’actualité des informations diffusées sur le
        Site, ni la permanence de son bon fonctionnement ou sa totale sécurité informatique. Les informations
        fournies sur le Site, le sont à titre indicatif sans aucune garantie, explicite ou implicite, de validité,
        d’exhaustivité ou d’actualité.
      </p>
      <p>
        LDE ne pourra pas être tenue responsable en cas d’indisponibilité du site, pour quelque cause que ce soit.
        Elle ne saurait non plus être tenue responsable des erreurs, d’une absence de disponibilités des
        informations et/ou de la présence de virus sur le Site.
      </p>
      <p>
        LDE ne sera responsable ni des dommages temporaires ou permanents causés au système informatique de
        l’Utilisateur, ni des pertes ou dommages éventuels (y compris la perte de données ou de profits) qui
        seraient subis à la suite de&nbsp;:
        <ul>
          <li>l’accès ou à la navigation sur le Site;</li>
          <li>l’exploitation des informations contenues dans le Site.</li>
        </ul>
      </p>
      <p>
        LDE ne sera pas non plus responsable des dommages indirects résultant d’une intrusion frauduleuse d’un
        tiers ayant entraîné une modification des informations diffusées sur le Site ou un préjudice financier ou
        commercial ou autre à l’Utilisateur.
      </p>
      <p>
        L’Utilisateur reconnaît avoir pris connaissance des CGU et s’engage à les respecter. L’Utilisateur
        reconnaît également utiliser ces informations sous sa seule et unique responsabilité.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 8&nbsp;: Liens hypertextes
      </h2>
      <p>
        Les liens hypertextes mis en place sur le Site en direction vers d’autres sites, principalement vers les
        sites de partenaires de LDE ont fait l’objet d’une autorisation expresse et préalable. LDE n’exerce aucun
        contrôle sur lesdits liens sur ces sites et n’accepte aucune responsabilité à leur égard, les pages
        restant de la seule responsabilité de leurs titulaires.
      </p>
      <p>
        Il est interdit de mettre en place un lien hypertexte en direction du Site LDE sans l'autorisation
        expresse et préalable de LDE. Dans l'hypothèse où un Utilisateur, un visiteur ou un partenaire
        souhaiterait mettre en place un lien hypertexte en direction du Site de LDE, il lui appartiendra
        d'adresser un courriel via l’adresse accessible sur le site afin de formuler sa demande de mise en place
        d'un lien hypertexte. LDE se réserve le droit d’accepter ou de refuser sans avoir à justifier sa décision.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 9&nbsp;: Loi applicable et juridiction compétente
      </h2>
      <p>
        La législation française s'applique aux présentes CGU. En cas d'absence de résolution amiable d'un litige
        né entre les parties, les tribunaux français de la juridiction de Strasbourg seront seuls compétents pour
        en connaître.
      </p>
      <address>
        Pour toute question relative à l’application des présentes CGU, vous pouvez joindre LDE par courriel ou
        par voie postale.<br />
        Adresse courriel&nbsp;:
        <a :href="`mailto:${mailLde}`">{{ mailLde }}</a>
        <br />
        Adresse&nbsp;: 165, rue du Château 67230 WESTHOUSE
      </address>
    </div>
  </div>
</template>

<script>
import config from "@/config";

/**
 * Affiche la page des CGU de Cristal Commandes en français.
 */
export default {
  name: "CguCristalCommandesFr",
  computed: {
    mailLde() {
      return config.mailsFr.lde;
    },
  },
};
</script>

<style lang="scss"></style>
